// import React from 'react'
// import "./Social1.css";
// import img from "../../assets/s1.png";
// import img1 from "../../assets/s2.png";
// import img2 from "../../assets/s3.png";
// import img3 from "../../assets/s4.png";
// import img4 from "../../assets/s5.png";
// import img5 from "../../assets/s6.png";

// const Social1 = () => {
//   return (
//     <div>
//       <h1 className='social-heading font-jakarta mt-20 h-24'>Our Backers</h1>
//       <div className=" max-w-5xl mx-auto flex gap-4 mt-8">
// <img src={img} alt="" style={{width: "247px", height: "46px"}} />
// <img src={img1} alt="" style={{width: "119px", height: "54px"}} />
// <img src={img2} alt="" style={{width: "179px", height: "44px"}} />
// <img src={img3} alt="" style={{width: "202px", height: "52px"}} />
// <img src={img4} alt="" style={{width: "127px", height: "64px"}} />
// <img src={img5} alt="" style={{width: "96px", height: "52px"}} />
//       </div>
//     </div>
//   )
// }

// export default Social1import React from "react";
// import Marquee from "react-fast-marquee";
// import { motion } from "framer-motion";
// import img from "../../assets/s1.png";
// import img1 from "../../assets/s2.png";
// import img2 from "../../assets/s3.png";
// import img3 from "../../assets/s4.png";
// import img4 from "../../assets/s5.png";
// import img5 from "../../assets/s6.png";

// const backers = [
//   { image: img, width: "247px", height: "46px", name: "CreditBook" },
//   { image: img1, width: "119px", height: "54px", name: "XDC" },
//   { image: img2, width: "179px", height: "44px", name: "Zorox" },
//   { image: img3, width: "202px", height: "52px", name: "TDefi" },
//   { image: img4, width: "127px", height: "64px", name: "Crypto Valley" },
//   { image: img5, width: "96px", height: "52px", name: "OtherBacker" },
// ];

// export default function OurBackers() {
//   return (
//     <div className="my-20 px-4 sm:px-6 md:px-8 overflow-hidden">
//       {/* Backers Title */}
//       <div className="text-center mb-12 sm:mb-16 md:mb-20 lg:mb-24">
//         <h2 className="text-white text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold">
//           Our Backers
//         </h2>
//       </div>

//       {/* Marquee for Backers with Hover Effects */}
//       <Marquee
//         gradient={false}
//         speed={60}
//         pauseOnHover={true}
//         className="flex gap-8 overflow-hidden"
//       >
//         {backers.map((backer, index) => (
//           <motion.div
//             key={index}
//             className="mx-4 p-4"
//             whileHover={{
//               scale: 1.1,
//               transition: { duration: 0.4, ease: "easeOut" },
//             }}
//             style={{ transformOrigin: "center" }} // Smooth scaling
//           >
//             <img
//               src={backer.image}
//               alt={backer.name}
//               className="hover:scale-110 transition-transform duration-300"
//               style={{
//                 width: backer.width,
//                 height: backer.height,
//                 objectFit: "contain",
//                 filter: "brightness(0) invert(1)", // To ensure logos are visible even if they're white
//               }}
//             />
//           </motion.div>
//         ))}
//       </Marquee>
//     </div>
//   );
// }
import React from "react";
import Marquee from "react-fast-marquee";
import "./Social1.css";
import { motion } from "framer-motion";
import img from "../../assets/Iota.png";
import img1 from "../../assets/Brinc.png";
import img3 from "../../assets/MBRIF.png";
import img4 from "../../assets/DFINITY.png";
import img5 from "../../assets/TDeFi.png";
import img6 from "../../assets/CryptoOasis.png";
import img7 from "../../assets/di.webp";
import img8 from "../../assets/black.webp";
import img9 from "../../assets/sma.webp";
import img10 from "../../assets/DIFC.png";

const backers = [
  { image: img, name: "IOTA" },
  { image: img1, name: "Brinc" },
  { image: img3, name: "Muhammad Bin Rashid Foundation" },
  { image: img4, name: "Dfinity" },
  { image: img5, name: "TDefi" },
  { image: img6, name: "Crypto Oasis" },
  { image: img8, name: "Block Alpha Capital " },
  { image: img9, name: "SMA Family Office" },
  { image: img10, name: "DIFC" },
];

export default function OurBackers() {
  return (
    <div className="mt-24 px-4 sm:px-6 md:px-8 lg:px-32 overflow-hidden"> {/* Added extra padding on larger screens */}
      {/* Backers Title */}
      <div className="text-center">
        <h2 className="social1-h text-xl sm:text-2xl md:text-3xl lg:text-3xl font-jakarta">
          OUR BACKERS
        </h2>
      </div>

      {/* Marquee for Backers with Hover Effects */}
      <Marquee
        gradient={false}
        speed={50} /* Adjust speed for smoothness */
        pauseOnHover={true}
        className="flex gap-2 overflow-hidden"
      >
        {backers.map((backer, index) => (
          <motion.div
            key={index}
            className="mx-1 p-2"
            whileHover={{
              scale: 1.1,
              transition: { duration: 0.4, ease: "easeOut" },
            }}
            style={{ transformOrigin: "center" }} // Smooth scaling
          >
            <img
              src={backer.image}
              alt={backer.name}
              className="hover:scale-110 transition-transform duration-300 w-40 h-40  object-contain"
              style={{              
                margin: "0 auto", // Center the logos horizontally
              }}
            />
          </motion.div>
        ))}
      </Marquee>
    </div>
  );
}
