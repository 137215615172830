// import { motion } from "framer-motion";
// import CountUp from "react-countup"; // For counting effect

// export default function Counter() {
//   return (
//     <div className="counter max-w-7xl mx-auto text-white px-6 sm:px-12 py-10 mt-10 rounded-2xl bg-gradient-to-r from-blue-800 to-blue-600 shadow-lg">
//       <div className="grid grid-cols-1 sm:grid-cols-3 gap-6">
//         {/* Counter Item 1 */}
//         <motion.div
//           initial={{ opacity: 0, scale: 0.8 }}
//           animate={{ opacity: 1, scale: 1 }}
//           transition={{ duration: 0.8, ease: "easeOut", delay: 0.2 }}
//           className="text-center p-4 rounded-lg bg-gradient-to-b from-blue-700 to-blue-500 hover:scale-105 transition-transform duration-300"
//         >
//           <p className="text-3xl sm:text-4xl md:text-5xl font-bold text-teal-300">
//             <CountUp end={263000000} duration={4} separator="," />+
//           </p>
//           <p className="text-lg sm:text-xl md:text-2xl mt-2 text-white">
//             Assets Tokenized
//           </p>
//         </motion.div>

//         {/* Counter Item 2 */}
//         <motion.div
//           initial={{ opacity: 0, scale: 0.8 }}
//           animate={{ opacity: 1, scale: 1 }}
//           transition={{ duration: 0.8, ease: "easeOut", delay: 0.4 }}
//           className="text-center p-4 rounded-lg bg-gradient-to-b from-green-700 to-green-500 hover:scale-105 transition-transform duration-300"
//         >
//           <p className="text-3xl sm:text-4xl md:text-5xl font-bold text-green-300">
//             <CountUp end={47800000} duration={4} separator="," />+
//           </p>
//           <p className="text-lg sm:text-xl md:text-2xl mt-2 text-white">
//             Invoice Financed
//           </p>
//         </motion.div>

//         {/* Counter Item 3 */}
//         <motion.div
//           initial={{ opacity: 0, scale: 0.8 }}
//           animate={{ opacity: 1, scale: 1 }}
//           transition={{ duration: 0.8, ease: "easeOut", delay: 0.6 }}
//           className="text-center p-4 rounded-lg bg-gradient-to-b from-red-700 to-red-500 hover:scale-105 transition-transform duration-300"
//         >
//           <p className="text-3xl sm:text-4xl md:text-5xl font-bold text-red-300">
//             <CountUp end={0} duration={4} />+
//           </p>
//           <p className="text-lg sm:text-xl md:text-2xl mt-2 text-white">
//             Bad Debts
//           </p>
//         </motion.div>
//       </div>
//     </div>
//   );
// }

// import { motion } from "framer-motion";
// import CountUp from "react-countup"; // For counting effect

// export default function Counter() {
//   return (
//     <div className="counter max-w-7xl mx-auto text-white px-6 sm:px-12 py-10 mt-10 rounded-lg bg-blue-600 shadow-lg">
//       <div className="grid grid-cols-1 sm:grid-cols-3 gap-6 text-center">
//         {/* Counter Item 1 */}
//         <motion.div
//           initial={{ opacity: 0, scale: 0.8 }}
//           animate={{ opacity: 1, scale: 1 }}
//           transition={{ duration: 0.8, ease: "easeOut", delay: 0.2 }}
//           className="p-6 rounded-lg bg-blue-700 hover:scale-105 transition-transform duration-300"
//         >
//           <p className="text-4xl sm:text-5xl font-bold text-teal-200">
//             <CountUp end={263000000} duration={4} separator="," />+
//           </p>
//           <p className="text-lg sm:text-xl mt-2 text-white">
//             Assets Tokenized
//           </p>
//         </motion.div>

//         {/* Counter Item 2 */}
//         <motion.div
//           initial={{ opacity: 0, scale: 0.8 }}
//           animate={{ opacity: 1, scale: 1 }}
//           transition={{ duration: 0.8, ease: "easeOut", delay: 0.4 }}
//           className="p-6 rounded-lg bg-blue-700 hover:scale-105 transition-transform duration-300"
//         >
//           <p className="text-4xl sm:text-5xl font-bold text-teal-200">
//             <CountUp end={47800000} duration={4} separator="," />+
//           </p>
//           <p className="text-lg sm:text-xl mt-2 text-white">
//             Invoice Financed
//           </p>
//         </motion.div>

//         {/* Counter Item 3 */}
//         <motion.div
//           initial={{ opacity: 0, scale: 0.8 }}
//           animate={{ opacity: 1, scale: 1 }}
//           transition={{ duration: 0.8, ease: "easeOut", delay: 0.6 }}
//           className="p-6 rounded-lg bg-blue-700 hover:scale-105 transition-transform duration-300"
//         >
//           <p className="text-4xl sm:text-5xl font-bold text-teal-200">
//             <CountUp end={0} duration={4} />+
//           </p>
//           <p className="text-lg sm:text-xl mt-2 text-white">
//             Bad Debts
//           </p>
//         </motion.div>
//       </div>
//     </div>
//   );
// }
// import { motion } from "framer-motion";
// import CountUp from "react-countup";
// import "./Counter.css";

// export default function Counter() {
//   return (
//     <div className="counter max-w-6xl mx-auto text-white px-28 sm:px-12 py-2 mt-4 bg-blue-600 shadow-lg rounded-2xl">
//       <div className="grid grid-cols-1 sm:grid-cols-3 gap-6 text-center">
//         {/* Counter Item 1 */}
//         <motion.div
//           initial={{ opacity: 0, scale: 0.8 }}
//           animate={{ opacity: 1, scale: 1 }}
//           transition={{ duration: 0.8, ease: "easeOut", delay: 0.2 }}
//           className="p-4"
//         >
//           <p className="counter-text font-manrope" style={{color: "#57FFFF"}}>
//             <CountUp end={263000000} duration={4} separator="," />+
//           </p>
//           <p className="counter-p font-manrope">Assets Tokenized</p>
//         </motion.div>

//         {/* Counter Item 2 */}
//         <motion.div
//           initial={{ opacity: 0, scale: 0.8 }}
//           animate={{ opacity: 1, scale: 1 }}
//           transition={{ duration: 0.8, ease: "easeOut", delay: 0.4 }}
//           className="p-4"
//         >
//           <p className="counter-text font-manrope" style={{color: "#57FFFF"}}>
//             <CountUp end={47000000} duration={4} separator="," />+
//           </p>
//           <p className="counter-p">Invoice Financed</p>
//         </motion.div>

//         {/* Counter Item 3 */}
//         <motion.div
//           initial={{ opacity: 0, scale: 0.8 }}
//           animate={{ opacity: 1, scale: 1 }}
//           transition={{ duration: 0.8, ease: "easeOut", delay: 0.6 }}
//           className="p-4"
//         >
//           <p className="counter-text font-manrope" style={{color: "#57FFFF"}}>
//             <CountUp end={0} duration={4} />
//           </p>
//           <p className="counter-p">Bad Debts</p>
//         </motion.div>
//       </div>
//     </div>
//   );
// }


import { motion } from "framer-motion";
import CountUp from "react-countup";
import "./Counter.css";

export default function Counter() {
  return (
    <div className="counter max-w-6xl mx-auto text-white px-4 sm:px-12 py-2 mt-4 bg-blue-600 shadow-lg rounded-2xl">
      <div className="grid grid-cols-1 sm:grid-cols-3 gap-6 text-center">
        {/* Counter Item 1 */}
        <motion.div
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.8, ease: "easeOut", delay: 0.2 }}
          className="p-4 flex flex-col items-center"
        >
          <p className="counter-text font-manrope text-center" style={{ color: "#57FFFF" }}>
            <CountUp end={263000000} duration={4} separator="," />+
          </p>
          <p className="counter-p font-manrope text-center">Assets Tokenized</p>
        </motion.div>

        {/* Counter Item 2 */}
        <motion.div
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.8, ease: "easeOut", delay: 0.4 }}
          className="p-4 flex flex-col items-center"
        >
          <p className="counter-text font-manrope text-center" style={{ color: "#57FFFF" }}>
            <CountUp end={47000000} duration={4} separator="," />+
          </p>
          <p className="counter-p font-manrope text-center">Invoice Financed</p>
        </motion.div>

        {/* Counter Item 3 */}
        <motion.div
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.8, ease: "easeOut", delay: 0.6 }}
          className="p-4 flex flex-col items-center"
        >
          <p className="counter-text font-manrope text-center" style={{ color: "#57FFFF" }}>
            <CountUp end={0} duration={4} />
          </p>
          <p className="counter-p font-manrope text-center">Bad Debts</p>
        </motion.div>
      </div>
    </div>
  );
}
