// import React from "react";
// import "./Decentra.css";
// import img from "../../assets/d1.png";
// import img1 from "../../assets/d2.png";
// import img2 from "../../assets/d3.png";
// import img3 from "../../assets/d4.png";

// const FeatureCard = ({ title, description, imageSrc, para }) => (
//   <div className="flex flex-col items-center">
//     <div className="mb-4">
//       <img src={imageSrc} alt={title} className="object-contain" style={{width: "520px" , height: "427px"}} />
//     </div>
//     <h3 className="decentra-h font-manrope">
//       {title}
//     </h3>
//     <p className="decentra-p mt-4 font-manrope text-justify">{description}</p>
//     <p className="decentra-p mt-1 font-manrope text-justify">{para}</p>
//   </div>
// );

// const Decentra = () => {
//   const features = [
//     {
//       title: "Single-Asset Liquidity Pools",
//       description:
//         "Investors Earn Additional Yields and Share of Protocol Revenue.",
//       imageSrc: img,
//     },
//     {
//       title: "RISK-ADJUSTED APY",
//       description:
//         "DeFi investors can earn up to 26%* APY even in BEAR markets derived from real economic activity!",
//       imageSrc: img1,
//     },
//     {
//       title: "NEW CONSENSUS MECHANISM",
//       description:
//         "Automated, Immutable Invoice Verification & Risk Scoring Mechanism",
//         para: "Transforming Inefficient Invoices into On-chain Financing Instruments via Know Your Invoice (KYI)",
//       imageSrc: img2,
//     },
//     {
//       title: "QPY (QUARTERLY PERCENTAGE YIELD)",
//       description:
//         "Offering short-term APY compared to the slower returns from traditional DeFi swapping and staking.",
//       imageSrc: img3,
//     },
//   ];

//   return (
//     <div className=" max-w-5xl mx-auto p-8 mt-36">
//       <h1 className="de-heading text-center font-silkScreen">
//         Defa is a decentralized factory
//       </h1>
//       <p className="de-para text-center font-manrope mt-3">
//         DeFa is the Decentralized Invoice Factoring Protocol
//       </p>
//       <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-5xl mx-auto mt-14">
//         {features.map((feature, index) => (
//           <FeatureCard key={index} {...feature} />
//         ))}
//       </div>
//     </div>
//   );
// };

// export default Decentra;

// import React from "react";
// import { motion } from "framer-motion";
// import img from "../../assets/d1.png";
// import img1 from "../../assets/d2.png";
// import img2 from "../../assets/d3.png";
// import img3 from "../../assets/d4.png";
// import "./Decentra.css";

// const FeatureCard = ({ title, description, imageSrc, para }) => (
//   <motion.div
//     initial={{ opacity: 0, y: 50 }}
//     whileInView={{ opacity: 1, y: 0 }}
//     transition={{ duration: 0.8, ease: "easeOut" }}
//     viewport={{ once: true }}
//     className="flex flex-col items-center p-6 bg-gradient-to-r from-blue-900 to-blue-600 shadow-lg rounded-xl transform transition duration-500 hover:scale-105"
//   >
//     <motion.div
//       whileHover={{ scale: 1.05, rotate: 2 }}
//       transition={{ duration: 0.4, ease: "easeInOut" }}
//       className="mb-6"
//     >
//       <img
//         src={imageSrc}
//         alt={title}
//         className="w-full h-auto object-contain rounded-lg"
//       />
//     </motion.div>
//     <h3 className="decentra-h text-white text-2xl md:text-3xl lg:text-4xl font-bold uppercase mb-4">
//       {title}
//     </h3>
//     <p className="decentra-p text-lg md:text-xl text-gray-200 mb-2">
//       {description}
//     </p>
//     {para && (
//       <p className="decentra-p text-lg md:text-xl text-gray-300">{para}</p>
//     )}
//   </motion.div>
// );

// const Decentra = () => {
//   const features = [
//     {
//       title: "Single-Asset Liquidity Pools",
//       description:
//         "Investors Earn Additional Yields and Share of Protocol Revenue.",
//       imageSrc: img,
//     },
//     {
//       title: "RISK-ADJUSTED APY",
//       description:
//         "DeFi investors can earn up to 26%* APY even in BEAR markets derived from real economic activity!",
//       imageSrc: img1,
//     },
//     {
//       title: "NEW CONSENSUS MECHANISM",
//       description:
//         "Automated, Immutable Invoice Verification & Risk Scoring Mechanism",
//       para: "Transforming Inefficient Invoices into On-chain Financing Instruments via Know Your Invoice (KYI)",
//       imageSrc: img2,
//     },
//     {
//       title: "QPY (QUARTERLY PERCENTAGE YIELD)",
//       description:
//         "Offering short-term APY compared to the slower returns from traditional DeFi swapping and staking.",
//       imageSrc: img3,
//     },
//   ];

//   return (
//     <div className="max-w-7xl mx-auto px-6 py-24">
//       {/* Retaining original heading styling */}
//       <motion.h1
//         initial={{ opacity: 0, y: -50 }}
//         whileInView={{ opacity: 1, y: 0 }}
//         transition={{ duration: 0.8, ease: "easeOut" }}
//         viewport={{ once: true }}
//         className="de-heading text-center font-silkScreen text-4xl md:text-5xl lg:text-6xl"
//       >
//         Defa is a decentralized factory
//       </motion.h1>
//       <motion.p
//         initial={{ opacity: 0, y: -50 }}
//         whileInView={{ opacity: 1, y: 0 }}
//         transition={{ duration: 0.8, delay: 0.2, ease: "easeOut" }}
//         viewport={{ once: true }}
//         className="de-para text-center text-2xl md:text-3xl mt-3 font-manrope"
//       >
//         DeFa is the Decentralized Invoice Factoring Protocol
//       </motion.p>
//       <div className="grid grid-cols-1 md:grid-cols-2 gap-10 mt-14">
//         {features.map((feature, index) => (
//           <FeatureCard key={index} {...feature} />
//         ))}
//       </div>
//     </div>
//   );
// };

// export default Decentra;
import React from "react";
import { motion } from "framer-motion";
import img from "../../assets/d1.png";
import img1 from "../../assets/d2.png";
import img2 from "../../assets/d3.png";
import img3 from "../../assets/d4.png";
import "./Decentra.css";

const FeatureCard = ({ title, description, imageSrc, para }) => (
  <motion.div
    initial={{ opacity: 0, y: 50 }}
    whileInView={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.8, ease: "easeOut" }}
    viewport={{ once: true }}
    className="flex flex-col items-center p-6 transform transition-all duration-700 hover:scale-105"
  >
    <motion.div
      whileHover={{ scale: 1.1, rotate: 2 }}
      transition={{ duration: 0.5, ease: "easeInOut" }}
      className="mb-6 w-full h-auto"
    >
      <img
        src={imageSrc}
        alt={title}
        className="w-full h-auto object-contain rounded-lg"
      />
    </motion.div>
    <center>
      <h3 className="decentra-h text-white text-2xl md:text-3xl lg:text-4xl font-manrope mb-4">
        {title}
      </h3>
    </center>

    <p className="decentra-p text-lg lg:text-2xl md:px-2.5">{description}</p>
  </motion.div>
);

const Decentra = () => {
  const features = [
    {
      title: "Single-Asset Liquidity Pools",
      description:
        "Investors Earn Additional Yields and Share of Protocol Revenue.",
      imageSrc: img,
    },
    {
      title: "RISK-ADJUSTED APY",
      description:
        "DeFi investors can earn up to 26%* APY even in BEAR markets derived from real economic activity!",
      imageSrc: img1,
    },
    {
      title: "NEW CONSENSUS MECHANISM",
      description:
        "Automated, Immutable Invoice Verification & Risk Scoring Mechanism. Transforming Inefficient Invoices into On-chain Financing Instruments via Know Your Invoice (KYI)",
      imageSrc: img2,
    },
    {
      title: (
        <>
          QUARTERLY PERCENTAGE YIELD
        </>
      ),
      description:
        "Offering short-term APY compared to the slower returns from traditional DeFi swapping and staking.",
      imageSrc: img3,
    },
  ];

  return (
    <div className="max-w-6xl mx-auto px-6 pt-32">
      {/* Enhanced heading styling and animations */}
      <motion.h1
        initial={{ opacity: 0, y: -50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, ease: "easeOut" }}
        viewport={{ once: true }}
        className="de-heading text-center font-silkScreen text-4xl md:text-4xl lg:text-4xl text-transparent bg-clip-text bg-gradient-to-r from-teal-400 to-cyan-600"
      >
        DeFa is the Decentralized Invoice Factoring Protocol
      </motion.h1>
      {/* <motion.p
        initial={{ opacity: 0, y: -50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, delay: 0.2, ease: "easeOut" }}
        viewport={{ once: true }}
        className="de-para text-center text-2xl md:text-3xl mt-3 font-manrope text-gray-200"
      >
        DeFa is the Decentralized Invoice Factoring Protocol
      </motion.p> */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-6xl mx-auto mt-14">
        {features.map((feature, index) => (
          <FeatureCard key={index} {...feature} />
        ))}
      </div>
    </div>
  );
};

export default Decentra;
